body {
    padding-bottom: 100px;
}
button {
    transition-duration: 0.15s;
}
button:hover {
    background-color: gray;
    /* Green */
    color: white;
}
.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}
.App-header {
    background-color: #4a306d;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 1vmin);
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.App-header a:link,
.App-header a:visited {
    color: yellow;
    text-transform: uppercase;
    font-weight: 600;
    font-size: calc(12px + 1vmin);
}
.App-header > div {
    line-height: 2;
}
.App-link {
    color: #61dafb;
}
.greeting {
    font-size: calc(8px + 1vmin);
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
/* Predictions page */
form > * {
    box-sizing: border-box;
}
.generateButton {
    background-color: lightgrey;
    border: none;
    color: black;
    margin: 15px 0 15px 0;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
.generateButton:disabled {
    opacity: 0.5;
}
.grow {
    flex-grow: 1;
}
.saveButton {
    background-color: #d14836;
    border: none;
    color: white;
    margin: 15px 0 15px 0;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
.saveButton:disabled {
    opacity: 0.5;
}
.predictionAnswer {
    line-height: 1.5;
}
.predictionAnswerBlock {
    padding-top: 10px;
    padding-bottom: 10px;
}
.predictionBlock {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
}
.predictionInput {
    max-width: 100%;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;
}
.predictionBlockListItem {
    width: 100%;
}
.predictionsTable {
    /* display: table;
    */
    width: 100%;
}
.predictionsEventTitle {
    color: darkred;
    font-size: calc(10px + 2.5vmin);
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
}
.predictionsEventDate {
    font-size: calc(8px + 1vmin);
    font-weight: 500;
    padding-bottom: 10px;
}
.pbp-search-input > input {
    border: 1px solid black;
    background-color: rgba(253, 255, 255, 1);
    font-size: 18px;
    outline: none;
    padding: 10px 5px;
    position: relative;
}
.MuiMenuItem-root {
    min-height: 48px !important;
}
.MuiAutocomplete-option {
    min-height: 48px !important;
}
